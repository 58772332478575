import React, { useState, useEffect } from 'react';
import './styles.css';

function ColorOfTheDay() {
  const [color, setColor] = useState(null);
  const [colorName, setColorName] = useState('');

  const colors = [
    { name: 'Red', hex: '#FF0000' },
    { name: 'Green', hex: '#00FF00' },
    { name: 'Blue', hex: '#0000FF' },
    { name: 'White', hex: '#FFFFFF' },
    { name: 'Black', hex: '#000000' },
    { name: 'Yellow', hex: '#FFFF00' },
    { name: 'Cyan', hex: '#00FFFF' },
    { name: 'Magenta', hex: '#FF00FF' },
    { name: 'Silver', hex: '#C0C0C0' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Maroon', hex: '#800000' },
    { name: 'Olive', hex: '#808000' },
    { name: 'Purple', hex: '#800080' },
    { name: 'Teal', hex: '#008080' },
    { name: 'Navy', hex: '#000080' },
    { name: 'Lime', hex: '#00FF00' },
    { name: 'Light Steel Blue', hex: '#B0C4DE' },
    { name: 'Orange', hex: '#FFA500' },
    { name: 'Brown', hex: '#A52A2A' },
    { name: 'Gold', hex: '#FFD700' },
    { name: 'Beige', hex: '#F5F5DC' },
    { name: 'Coral', hex: '#FF7F50' },
    { name: 'Salmon', hex: '#FA8072' },
    { name: 'Khaki', hex: '#F0E68C' },
    { name: 'Indigo', hex: '#4B0082' },
    { name: 'Violet', hex: '#EE82EE' },
    { name: 'Turquoise', hex: '#40E0D0' },
    { name: 'Lavender', hex: '#E6E6FA' },
    { name: 'Sky Blue', hex: '#87CEEB' },
    { name: 'Chocolate', hex: '#D2691E' },
    { name: 'Tomato', hex: '#FF6347' },
    { name: 'Plum', hex: '#DDA0DD' },
    { name: 'Orchid', hex: '#DA70D6' },
    { name: 'Mint', hex: '#98FF98' },
    { name: 'Ivory', hex: '#FFFFF0' },
    { name: 'Lemon Chiffon', hex: '#FFFACD' },
    { name: 'Sandy Brown', hex: '#F4A460' },
    { name: 'Light Sea Green', hex: '#20B2AA' },
    { name: 'Medium Slate Blue', hex: '#7B68EE' },
    { name: 'Crimson', hex: '#DC143C' },
    { name: 'Sea Green', hex: '#2E8B57' },
    { name: 'Slate Gray', hex: '#708090' },
    { name: 'Royal Blue', hex: '#4169E1' },
    { name: 'Hot Pink', hex: '#FF69B4' },
    { name: 'Deep Pink', hex: '#FF1493' },
    { name: 'Forest Green', hex: '#228B22' },
    { name: 'Steel Blue', hex: '#4682B4' },
    { name: 'Dodger Blue', hex: '#1E90FF' },
    { name: 'Chartreuse', hex: '#7FFF00' },
    { name: 'Spring Green', hex: '#00FF7F' },
    { name: 'Medium Violet Red', hex: '#C71585' },
    { name: 'Midnight Blue', hex: '#191970' },
    { name: 'Dark Olive Green', hex: '#556B2F' },
    { name: 'Rosy Brown', hex: '#BC8F8F' },
    { name: 'Dark Orange', hex: '#FF8C00' },
    { name: 'Pale Violet Red', hex: '#DB7093' },
    { name: 'Light Coral', hex: '#F08080' },
    { name: 'Pale Green', hex: '#98FB98' },
    { name: 'Goldenrod', hex: '#DAA520' },
    { name: 'Dark Khaki', hex: '#BDB76B' },
    { name: 'Light Pink', hex: '#FFB6C1' },
    { name: 'Peach Puff', hex: '#FFDAB9' },
    { name: 'Light Goldenrod Yellow', hex: '#FAFAD2' },
    { name: 'Moccasin', hex: '#FFE4B5' },
    { name: 'Papaya Whip', hex: '#FFEFD5' },
    { name: 'Navajo White', hex: '#FFDEAD' },
    { name: 'Bisque', hex: '#FFE4C4' },
    { name: 'Antique White', hex: '#FAEBD7' },
    { name: 'Blanched Almond', hex: '#FFEBCD' },
    { name: 'Dark Sea Green', hex: '#8FBC8F' },
    { name: 'Light Sky Blue', hex: '#87CEFA' },
    { name: 'Pink', hex: '#FFC0CB' },
    { name: 'Powder Blue', hex: '#B0E0E6' },
    { name: 'Light Slate Gray', hex: '#778899' },
    { name: 'Slate Blue', hex: '#6A5ACD' },
    { name: 'Thistle', hex: '#D8BFD8' },
    { name: 'Medium Orchid', hex: '#BA55D3' },
    { name: 'Light Cyan', hex: '#E0FFFF' },
    { name: 'Honeydew', hex: '#F0FFF0' },
    { name: 'Azure', hex: '#F0FFFF' },
    { name: 'Alice Blue', hex: '#F0F8FF' },
    { name: 'Ghost White', hex: '#F8F8FF' },
    { name: 'Snow', hex: '#FFFAFA' },
    { name: 'Seashell', hex: '#FFF5EE' },
    { name: 'Floral White', hex: '#FFFAF0' },
    { name: 'Old Lace', hex: '#FDF5E6' },
    { name: 'Linen', hex: '#FAF0E6' },
    { name: 'Lavender Blush', hex: '#FFF0F5' },
    { name: 'Misty Rose', hex: '#FFE4E1' },
    { name: 'Dark Red', hex: '#8B0000' },
    { name: 'Dark Magenta', hex: '#8B008B' },
    { name: 'Dark Blue', hex: '#00008B' },
    { name: 'Dark Cyan', hex: '#008B8B' },
    { name: 'Dark Green', hex: '#006400' },
    { name: 'Dark Slate Gray', hex: '#2F4F4F' },
    { name: 'Dim Gray', hex: '#696969' },
    { name: 'Firebrick', hex: '#B22222' },
    { name: 'Indian Red', hex: '#CD5C5C' },
    { name: 'Light Salmon', hex: '#FFA07A' },
    { name: 'Sienna', hex: '#A0522D' },
  ];

  const generateColor = () => {
    const date = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const [month, day, year] = date.split(',')[0].split('/');
    
    // Create a simple hash based on the date
    const dateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    let hash = 0;
    for (let i = 0; i < dateString.length; i++) {
      const char = dateString.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32-bit integer
    }

    // Use the hash to generate an index
    const dateIndex = Math.abs(hash) % colors.length;
    return colors[dateIndex];
  };

  useEffect(() => {
    if (color) {
      document.getElementById('theme-color-meta').setAttribute('content', color);
      document.body.style.backgroundColor = color;
    }
  }, [color]);

  const handleClick = () => {
    const newColor = generateColor();
    setColor(newColor.hex);
    setColorName(newColor.name);
  };

  return (
    <div className="container">
      {!color ? (
        <button onClick={handleClick}>Color of the Day</button>
      ) : (
        <div className="color-info">
          <div className="color-name">{colorName.toUpperCase()}</div>
          <div className="color-value">{color.toUpperCase()}</div>
        </div>
      )}
    </div>
  );
}

export default ColorOfTheDay;